import { RegisterType } from '@/utils/type';
import { defineStore } from 'pinia';
import { reactive } from 'vue';

export const useRegisterStoreSetup = defineStore('register', () => {
    /**
     * 数据
     */
    const registerList = reactive<RegisterType>({
        topWidth: 1200,
	    hasBtn: true,
	    hasReg: false,
	    hasLoginOut: true,
        tips: ''
    });

    /**
    * 设置top数据
   */
    const setRegisterTop = (e:RegisterType) => {
        registerList.topWidth = e.topWidth || registerList.topWidth;
        registerList.hasBtn = e.hasBtn;
        registerList.hasReg = e.hasReg;
        registerList.hasLoginOut = e.hasLoginOut;
        registerList.tips = e.tips || registerList.tips;
    };

    return {
        registerList,
        setRegisterTop,
    };
});
