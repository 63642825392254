import { LocakStorageKeyType } from './../utils/localStorageSaveKey';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosError, AxiosInstance, AxiosPromise } from 'axios';
import { ElMessage } from 'element-plus';
import { AxionsFixedResponse } from './http.interface';
import { useUserInfoSetup } from '@/stores';
import { debounce } from '@/utils/debounce';
import { useRoute, useRouter } from "vue-router";
const routes = useRoute();
const router = useRouter();

export enum FileResponseType {
	Default,
	Other,
}
let callback = debounce (() => {
	// router.push( '/register/login?backurl=' + routes.fullPath)
	ElMessage.error('登录过期，请重新登录')
	router.push( '/register/login')
}, 500)

/**
 * 默认请求方式类型，剔除method
 */
type DefaultMethodAxiosRequestConfig = Omit<AxiosRequestConfig, 'method'>;

export enum Methods {
	GET = 'get',
	DELETE = 'delete',
	HEAD = 'head',
	OPTIONS = 'options',
	POST = 'post',
	PUT = 'put',
	PATCH = 'patch',
	PURGE = 'purge',
	LINK = 'link',
	UNLINK = 'unlink',
}

// 可修改配置
export default class HttpRequest {
	instance: AxiosInstance;
	// axios 基础配置
	config: AxiosRequestConfig = {
		baseURL: import.meta.env.VITE_BASE_URL,
		withCredentials: true,
	};
	// 构造
	constructor(options?: AxiosRequestConfig) {
		// 生成axios实例
		this.instance = axios.create(Object.assign(this.config, options || {}));
		// 注入拦截
		this.interceptors(this.instance);
	}

	// 拦截器
	interceptors(instance: AxiosInstance): void {
		// 请求拦截
		instance.interceptors.request.use(
		async (config: AxiosRequestConfig) => {
			const token = localStorage.getItem(LocakStorageKeyType.Token);
			// 塞入token验证
			if (token) {
			Object.assign(config.headers, {
				Authorization: token,
			});
			}
			// 配置
			return config;
		},
		(error: any) => {
			return Promise.reject(error.data);
		}
		);
		// 响应拦截
		instance.interceptors.response.use(adoptIntercept, errorIntercept);
	}

	/**
	 * http默认请求方式
	 * @param  默认请求参数 {@link AxiosRequestConfig}
	 * @returns 请求结果
	 */
	async request<T = AxionsFixedResponse, O = FileResponseType.Default>(
		options: AxiosRequestConfig
	): Promise<AxiosPromise<O extends FileResponseType.Default ? AxionsFixedResponse<T> : T>> {
		const { config } = this;
		const option = Object.assign({}, config, options);
		try {
		const res = await this.instance(option);
		return res;
		} catch (err) {
		throw err;
		}
	}

	/**
	 * http-get 请求
	 * @param  请求参数 {@link DefaultMethodAxiosRequestConfig}
	 * @returns 请求结果
	 */
	Get<T, R = FileResponseType.Default>(options: DefaultMethodAxiosRequestConfig) {
		(options as AxiosRequestConfig).method = Methods.GET;
		return this.request<T, R>(options);
	}
	/**
	 * http-post 请求
	 * @param  请求参数 {@link DefaultMethodAxiosRequestConfig}
	 * @returns 请求结果
	 */
	Post<T, R = FileResponseType.Default>(options: AxiosRequestConfig) {
		(options as AxiosRequestConfig).method = Methods.POST;
		return this.request<T, R>(options);
	}
	/**
	 * http-post 请求
	 * @param  请求参数 {@link DefaultMethodAxiosRequestConfig}
	 * @returns 请求结果
	 */
	Put<T, R = FileResponseType.Default>(options: AxiosRequestConfig) {
		(options as AxiosRequestConfig).method = Methods.PUT;
		return this.request<T, R>(options);
	}
}

/**
 * 成功拦截器
 */
const adoptIntercept = async (res: AxiosResponse<AxionsFixedResponse>) => {
	const priorUrl = ['api/system/Area'];
	// 如果是下载文件
	if (res.data instanceof Blob) {
		// 处理文件的请求
		await analysisBlob(res.data);
	}

	// 曾经的老接口 未统一封装
	let urls = res.config.url?.split('/')
	urls?.splice(urls.length - 1, 1)
	if (priorUrl.includes(urls?.join('/'))) {
		return res;
	}

	const { status, error } = res.data;
	if ( res.data.code && res.data.code === 600 ) {
		const userStoreSetup = useUserInfoSetup();
		// 删除信息 重新登录
		userStoreSetup.loginOut();
		callback()
		return Promise.reject(res);
	}
	// 如果有详细信息
	if (error?.name) {
		if('api/eryuan/sysadm/signup/' === res.config.url && (error.code === 'USER-REGISTER-CMD-02' || error.code === 'USER-REGISTER-CMD-06')) {
			ElMessage({
				grouping: true,
				message: '该手机号已注册',
				type: 'error'
			});
		} else {
			ElMessage({
				grouping: true,
				message: error?.name,
				type: 'error'
			});
		}
		return Promise.reject(res);
	}
	// 如果没有详细相信 但是报错
	if (status && status.code && status.code !== 200) {
		ElMessage({
			grouping: true,
			message: status?.name,
			type: 'error'
		});
		return Promise.reject(res);
	}
	return Promise.resolve(res);
};

/**
 * 失败拦截器
 */
const errorIntercept = async (error: AxiosError<AxionsFixedResponse<any>>) => {
  	return Promise.reject(error);
};

// blob 解析
const analysisBlob = (text: Blob): Promise<AxionsFixedResponse> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = (res) => {}; // 成功回调
		reader.onerror = (err) => reject(err); // 失败回调
		reader.readAsText(new Blob([text]), 'utf-8'); // 按照utf-8编码解析
	});
};
// 默认配置
export const $axios = new HttpRequest();
