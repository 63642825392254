import { watch } from 'vue';
import { useUserStoreSetup } from '@/stores';
import { UserPersonalInfo } from '@/stores/main/main-type';
export class HttpPoralCodeConnect {
  carrierText?: string;
  /**
   * 个人信息资料
   */
  userPersonalInfo: UserPersonalInfo | null = null;
  /**
   * 租户Code 前缀url
   */
  // TODO: 需要pinia注册之后才能载入
  get portalCode() {
    if (this.carrierText) return this.carrierText;
    const userStoreSetup = useUserStoreSetup();
    this.carrierText = userStoreSetup?.userPersonalInfo?.userInfo.lastLoginTenant.code;
    return this.carrierText || 'eryuan';
  }
}

export const httpPoralCodeConnect = new HttpPoralCodeConnect();
