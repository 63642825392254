/**
 * 项目中cookie 存储的字段枚举
 */
export enum LocakCookieKeyType {}

/**
 * 项目中Stroage 存储的字段枚举
 */
export enum LocakStorageKeyType {
    /**
     * 登录的token
    */
    Token = 'token',
    /**
     * 二级节点token
    */
    SecondaryNodeToken = 'SecondaryNodeToken',
    /**
     * 路由导航的堆栈
    */
    Historys = 'historys',

    /**
     * 记住密码
    */
    RememberedKeys= 'RememberedKeys',

    /**
     * CompanId 公司id
    */
    CompanId = 'companId'
}
