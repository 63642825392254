import { ElMessage } from "element-plus";
import { httpUserConnect } from "@/service/user";
import { LocakStorageKeyType } from "./localStorageSaveKey";
/**
 *  获取sso的相关配置
 */
interface ProfileSsoType {
  client: {
    url: string;
  };
  enabled: boolean;
  server: {
    login: string;
    logout: string;
    protocol: string;
    url: string;
  };
}

/**
 * 拿取sso凭证获取Login的信息
 */
interface Login {
  code: number;
  msg: string;
  data: {
    token: string;
    theme: string;
    assis: string;
  };
}

/**
 * 登录权限相关
 */
class LoginCasOperation {
  /**
   * 单点登录的配置信息
   */
  profileSso!: ProfileSsoType;

  urlTokenKey = "ticket";

  /**
   * 获取单点登录的配置信息
   */
  async getProfileSsoInfo() {
    try {
      const { data } = await httpUserConnect.getProfileSso<ProfileSsoType>();
      this.profileSso = data;
    } catch (err) {
      throw err;
    }
  }
  /**
   * 登录
   */
  async login() {
    const token = localStorage.getItem(LocakStorageKeyType.Token);
    if (token) {
      return token;
    }

    try {
      // 登录逻辑
      await this.getProfileSsoInfo();
      //   判端是否启用单点登录
      if (this.profileSso.enabled) {
        const configTokens = this._split(window.location.href, `${this.urlTokenKey}=`);
        // 是否已经从sso跳转过来了
        if (configTokens.length === 2) {
          /**
           * @params `service` 重定向地址
           * @params `ticket` sso 返回凭证
           */
          const [service, ticket] = configTokens;

          const loginStr =
            "account=******&password=******&client_id=admin&client_secret=123456&scope=all&grant_type=password&cas_ticket=" +
            encodeURIComponent(ticket) +
            "&cas_service=" +
            encodeURIComponent(service);
          const { data } = await httpUserConnect.login<Login>(loginStr);
          //  手动判断请求是否有效
          if (data.code === 200) {
            const { token } = data.data;
            if (token) {
              localStorage.setItem(LocakStorageKeyType.Token, token);
              window.location.replace(service);
            }
          } else {
            alert(data)
            ElMessage.error(data.msg);
            throw data;
          }
        } else {
          // 去sso 登录
          const service = window.location.href;
          const url = this.profileSso.server.login + "?service=" + encodeURIComponent(service);
          window.location.replace(url);
        }
      }
    } catch (err) {
      throw err;
    }
  }
  /**
   * 退出登录
   */
  async loginOut() {
    try {
      // 刷新页面时 会丢失
      if (!this.profileSso) {
        await this.getProfileSsoInfo();
      }
      if (this.profileSso.enabled) {
        let url = this.profileSso.server.logout + "?service=" + encodeURIComponent(window.location.href);
        localStorage.removeItem(LocakStorageKeyType.Token);
        window.location.replace(url);
      }
    } catch (err) {
      ElMessage.error("退出失败");
      throw err;
    }
  }
  /**
   * url 中筛选出数据
   * @param url LinkURL
   * @param string Pick
   */
  private _split(url: string, tag: string): string[] {
    let ret;
    let sp = "?" + tag;
    if (url.indexOf(sp) !== -1) {
      ret = url.split(sp);
    } else {
      sp = "&" + tag;
      if (url.indexOf(sp) !== -1) {
        ret = url.split(sp);
      } else {
        ret = [];
      }
    }
    return ret;
  }
}

/**
 * 登录权限相关
 */
export const loginCasOperation = new LoginCasOperation();
