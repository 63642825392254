import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocakStorageKeyType } from '@/utils/localStorageSaveKey';
interface UserType  {
    tenant?: object | '',
    account?: string | '',
    name?: string | '',
    phoneNumber?: string | ''
};
import { httpUserConnect } from '@/service/newUser';

export const useUserInfoSetup = defineStore('user', () => {
    /**
     * 数据
     */
    const userInfo = ref(<UserType>{
        tenant: {
            name: ''
        },
        account: '',
        name: '',
        phoneNumber: ''
    });

    /**
    * 设置top数据
     */
    const setUserInfo = (e:UserType) => {
        userInfo.value = e
    };

    const getUserInfo = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await httpUserConnect.getUserInfo()
                let b = {
                    tenant: data.data.tenant,
                    account: data.data.account,
                    phoneNumber: data.data.phoneNumber,
                    name: data.data.name
                }
                userInfo.value = b
                resolve('')
            } catch (err) {
                reject(err)
            }
        })
    };

    const loginOut = () => {
        userInfo.value = {
            tenant: {
                name: ''
            },
            account: '',
            name: '',
            phoneNumber: ''
        },
        localStorage.removeItem(LocakStorageKeyType.Token)
        localStorage.removeItem(LocakStorageKeyType.CompanId)
    };

    return {
        userInfo,
        setUserInfo,
        getUserInfo,
        loginOut
    };
});
