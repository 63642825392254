import { httpConfigConnect } from '@/service/config';
import { DictionaryEnum, DictionaryResult, DictionaryResultList } from '@/utils/type';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useDictionaryStoreSetup = defineStore('dictionary', () => {
  /**
   * 职位list
   */
  const positionList = ref<DictionaryResultList[]>();

  /**
   * 获取职位list
   */
  const setPositionList = async () => {
    try {
      const { data } = await httpConfigConnect.getDictionaryEnum<DictionaryResult>(DictionaryEnum.Position);
      positionList.value = data.data.list;
    } catch (err) {}
  };

  return {
    positionList,
    setPositionList,
  };
});
