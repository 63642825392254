/**
 * 函数防抖
 * @param 执行函数
 * @param 时间间隔
 * @param 是否立即执行
*/

export const debounce = (func: Function, time: number = 300, immediate = false) => {
    let timer: number | null = null;
    return (...args: any) => {
        clearInterval(timer!)
        if (immediate) {
            if (!timer) func.apply(this, args);
            timer = window.setTimeout(() => {
                func.apply(this, args)
                timer = null
            }, time)
        } else {
            timer = window.setTimeout(() => {
                func.apply(this, args)
                timer = null
            }, time)
        }
    }
}