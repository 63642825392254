/**
 * 排序
 */
export enum OredrBy {
  /**
   * 降序
   */
  Desc = 'desc',
  /**
   * 升序
   */
  Asc = 'asc',
}

/**
 * 请求页面基本信息
 */
export interface RequestPaging {
  /**
   * 页码
   */
  _page: number;
  /**
   * 内容数量
   */
  _limit: number;
  /**
   * 排序
   */
  _order?: `${string}${OredrBy}` | '';
}

/**
 * 后端统一的返回体格式
 */
export interface AxionsFixedResponse<T = null> {
	data: T;
	status: {
		code: number;
		name: string;
	};
	error?: {
		code: string;
		name: string;
		details: string;
	};
}

/**
 * 分页返回体
 */
export interface PagingResponse<T = any[]> {
  /**
   * 返回数据
   */
  data: T;
  /**
   * 请求的第几页
   */
  page: number;
  /**
   * 每页记录数
   */
  limit: number;
  /**
   * 是否有上页
   */
  hasPrev: boolean;
  /**
   * 是否有下页
   */
  hasNext: boolean;
  /**
   * 查询返回记录数
   */
  count: number;
  /**
   * 总数 `total === null` 没有数据返回
   */
  total?: number;
}

/**
 * 默认分页返回体
 */
export type PageReponseAxios<T> = AxionsFixedResponse<PagingResponse<T>>;
