import { $axios, FileResponseType } from "@/http/api";

class HttpUserConnect {
  /**
   * 登录
   */
  login<T>(data: string) {
    return $axios.Post<T, FileResponseType.Other>({
      url: "api/oauth/Login",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      data
    });
  }

  /**
   * 获取个人用户信息
   */
  getUserInfo<T>() {
    return $axios.Get<T, FileResponseType.Other>({
      url: "api/eryuan/sysadm/user/profile"
    });
  }
}

/**
 * 人员权限接口集合
 */
export const httpUserConnect = new HttpUserConnect();
