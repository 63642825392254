import { $axios, FileResponseType } from '@/http/api';
import { DictionaryEnum } from '@/utils/type';
import { HttpPoralCodeConnect } from './poralCode';
/**
 * 一些配置接口字段
 */
class HttpConfigConnect extends HttpPoralCodeConnect {
  /**
   * 获取配置的 省/市/区
   */
  getAreaCascader<T>() {
    return $axios.Get<T, FileResponseType.Other>({
      url: '/api/area/get',
    });
  }

  /**
   * 获取配置字段接口
   */
  getDictionaryEnum<T>(type: DictionaryEnum) {
    return $axios.Get<T>({
      url: `/api/${this.portalCode}/sysadm/dictionary/${type}`,
    });
  }
}

/**
 * 一些配置接口字段
 */
export const httpConfigConnect = new HttpConfigConnect();
