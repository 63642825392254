import { OredrBy } from '@/http/http.interface';

/**
 * 配置接口的枚举字段
 */
export enum DictionaryEnum {
  /**
   * 职位枚举
   */
  Position = 'position',
}

/**
 * 配置字段的返回类型
 */

export interface DictionaryResult {
  list: DictionaryResultList[];
}

export interface DictionaryResultList {
  children?: DictionaryResultList[];
  enCode: string;
  enabledMark: number;
  fullName: string;
  hasChildren: boolean;
  id: string;
  parentId: string;
  sortCode: number;
}

/**
 * form 表单验证的类型
 */
export type FormRuleKindType = {
  [K in string]?: {
    required?: boolean;
    type?: string;
    message?: string;
    trigger: 'change' | 'blur';
  };
};

/**
 * tab类型
 */
export interface SelectTab {
  text: string;
  value: any;
  disappear?: boolean;
}

/**
 * element 排序的类型转换
 */


export const ElementSortType = {
  descending: OredrBy.Desc,
  ascending: OredrBy.Asc,
};


/**
 * 注册相关公用参数
 */


export interface RegisterType  {
    topWidth?: number | 1200;
    hasBtn?: boolean;
    hasReg?: boolean;
    hasLoginOut?: boolean;
    tips?: string | ''
};