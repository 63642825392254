import { ElMessage } from 'element-plus';
import { httpUserConnect } from '@/service/user';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { RouteInfo, UserPersonalInfo, UserPersonalInfoResponse } from './main-type';
import { LocakStorageKeyType } from '@/utils/localStorageSaveKey';
import { loginCasOperation } from '@/utils/cas';

/**
 * 权限路由中的扩展属性
*/
export type RouterInfoHasTopOverStory = RouteInfo & { topOverStory?: RouteInfo; flatPath?: string[] };
/**
 * 当前权限扁平化的路由类型
 */
type PersonalUrlMapType = Map<string, RouterInfoHasTopOverStory>;

export const useUserStoreSetup = defineStore('main', () => {
  /**
   * 个人信息
   */
  const userPersonalInfo = ref<UserPersonalInfo | null>(null);
  /**
   * 权限路由扁平化集合
   */
  const personalUrlMap = ref<PersonalUrlMapType | null>(null);
  /**
   * 当前选中的menu-type
   */
  const currtMenuKind = ref<RouteInfo | null>();
  /**
   * 登录凭证
   */
  const accessToken = ref('');
  /**
   * 获取个人信息
   */
  const getUserPersonal = async () => {
    try {
      const { data } = await httpUserConnect.getUserInfo<UserPersonalInfoResponse>();

      if (data.code !== 200) {
        ElMessage.error(data.msg);
        throw data;
      }

      userPersonalInfo.value = data.data;
    } catch (err) {
      throw err;
    }
  };

  /**
   * 删除个人信息
   */
  const loginOutRemoveUserInfo = async () => {
    try {
      accessToken.value = '';
      await loginCasOperation.loginOut();
      userPersonalInfo.value = null;
    } catch (err) {}
  };

  /**
   * 每一次的路由跳转(是否登录 是否有个人信息)判断
   */
  const loginEnroll = async () => {
    // 先获取token
    let token = localStorage.getItem(LocakStorageKeyType.Token);
    // 是否有token
    if (!token) {
      try {
        //   登录
        await loginCasOperation.login();
      } catch (err) {
        throw err;
      }
    } else {
      // 当代码中第一次没有token
      if (!accessToken.value) {
        accessToken.value = token;
      } else {
        // 比对本地缓存token 和 第一次赋值token
        if (accessToken.value !== token) {
          ElMessage.error('个人信息存在误差, 请重新登录');
          await loginOutRemoveUserInfo();
          loginEnroll();
        }
      }
    }
    // 判断是否有个人权限信息
    if (!userPersonalInfo.value) {
      try {
        //   个人信息
        await getUserPersonal();
      } catch (err) {
        throw err;
      }
    }
  };
  /**
   * 当有用户信息时，将路由扁平化
   */
  watch(
    () => userPersonalInfo.value,
    () => {
      if (userPersonalInfo.value) {
        personalUrlMap.value = personalUrlrecursion(userPersonalInfo.value?.menuList!);
      }
    }
  );

  return {
    loginEnroll,
    personalUrlMap,
    currtMenuKind,
    loginOutRemoveUserInfo,
    userPersonalInfo,
  };
});

/**
 * 权限扁平化操作
 */
const personalUrlrecursion = (
  menuList: RouterInfoHasTopOverStory[],
  map: PersonalUrlMapType = new Map(),
  topOverStory?: RouterInfoHasTopOverStory
): PersonalUrlMapType => {
  menuList.forEach((item) => {
    if (item.children) {
      personalUrlrecursion(item.children, map, topOverStory || item);
    } else {
      // 当前扁平化的父级
      (item as RouterInfoHasTopOverStory).topOverStory = topOverStory;
      // 父级收集扁平化的子集
      topOverStory!.flatPath = topOverStory?.flatPath || [];
      topOverStory?.flatPath.push(item.urlAddress);
      map.set(item.urlAddress, item);
    }
  });
  return map;
};
