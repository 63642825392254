import { defineStore } from 'pinia';
import { ref } from 'vue';

export const secondNodeInfoSetup = defineStore('secondNode', () => {
    /**
     * 数据
     */
    const loginFlag = ref(false);

    const changeLoginFlag = (a:boolean) => {
        console.log(a)
        loginFlag.value = a
    }

    return {
        loginFlag,
        changeLoginFlag
    };
});
